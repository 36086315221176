<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Áreas</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-text-field
          v-model="areaListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
          Las áreas disponibles para los usuarios dentro de la plataforma son fijas,
          es decir no se podrán agregar nuevas áreas ya que internamente se validan los permisos
          de los usuarios con el área que tengan configurada.
          Se recomienda que al realizar cambios en el nombre de alguna área se use un texto similar.
          Ej: Se modifica el nombre del área "Área Finanzas" por "Área Financiera"
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h5>Nombre</h5>
      </v-col>
      <v-col cols="4">
        <h5>Email</h5>
      </v-col>
      <v-col cols="2">
        <h5>Usuario mod</h5>
      </v-col>
      <v-col cols="3">
        <h5>Última actualización</h5>
      </v-col>
      <v-col cols="1">
        <h5>Acción</h5>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="areaList"
      :search="areaListSearch"
      loading-text="Cargando"
      :loading="areaListLoader"
      no-data-text="No hay áreas creadas"
      :items-per-page="8"
      :footer-props="{itemsPerPageOptions:[8, 16, -1]}"
    >
      <template v-slot:default="props">
          <div v-for="(areaItem, index) in props.items" :key="index">
          <v-row :class="index%2 === 0 ? 'backWhite' : 'backGray'">
            <v-col cols="2">
              <span> {{ areaItem.name }} </span>
            </v-col>
            <v-col cols="4">
              <span> {{ areaItem.email }} </span>
            </v-col>
            <v-col cols="2">
              <span> {{ areaItem.usermod }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ areaItem.updated }} </span>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editCountry(areaItem)"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          </div>
      </template>
  </v-data-iterator>
    <v-dialog
      max-width='500px'
      v-model="createAreaDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center-dialog centered-dialog__card">
          <v-icon class="mx-1">mdi-flag-outline</v-icon>
          <h4>{{ getCreateOrEdit() }} área</h4>
          <v-icon class="mx-1">mdi-flag-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="areaForm" v-model="areaForm.isValid">
            <v-row no-gutters>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="name">
                  Nombre
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="name"
                  outlined
                  single-line
                  background-color="white"
                  v-model="areaForm.name"
                />
              </v-col>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="email">
                  Email
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="email"
                  outlined
                  single-line
                  background-color="white"
                  v-model="areaForm.email"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :disabled="!areaForm.isValid"
                :loading="createOrEditBtnLoader"
                @click="updateArea()"
              >
                {{ getCreateOrEdit() }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Areas',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      areaList: [],
      areaListSearch: '',
      areaListLoader: false,
      createAreaDialog: false,
      areaForm: {
        name: '',
        email: '',
        id: '',
        isValid: ''
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false
    }
  },

  created () {
    this.areaListLoader = true
    this.getAreas()
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  methods: {
    ...mapActions('administration', ['getAdminAreas', 'getAdminArea', 'updateAdminAreas']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getAreas () {
      this.getAdminAreas()
        .then(response => {
          this.areaListLoader = false
          this.areaList = response.data
          this.closeDialog()
        })
        .catch(() => {
          this.areaListLoader = false
        })
    },

    editCountry (item) {
      this.isCreateOrUpdate = false
      this.areaForm.id = item.id
      this.areaForm.name = item.name
      this.areaForm.email = item.email
      this.createAreaDialog = true
    },

    updateArea () {
      this.createOrEditBtnLoader = true
      const request = {
        id: this.areaForm.id,
        name: this.areaForm.name,
        email: this.areaForm.email
      }
      this.updateAdminAreas(request)
        .then(() => {
          this.createOrEditBtnLoader = false
          this.getAreas()
          this.setSnackbar({
            text: 'Área actualizada correctamente',
            timeout: 5000,
            showing: true,
            color: colors.success
          })
        })
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    closeDialog () {
      this.createAreaDialog = false
      this.$refs.areaForm.resetValidation()
      this.areaForm = {
        name: '',
        email: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

</style>
